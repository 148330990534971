





























































































































































































@import '@design';
@import '@styleMixins';

.section--layout {
  padding-top: $size-grid-padding * 1;
  padding-bottom: $size-grid-padding * 1;
  &.no-img {
    padding-top: 0;
    padding-bottom: 0;

    @media (min-width: 1200px) {
      padding-top: $size-grid-padding * 3;
      padding-bottom: $size-grid-padding * 3;
    }
  }
  img {
    width: 100%;
  }
}

.img-wrap {
  position: relative;
  img {
    position: relative;
    z-index: 2;
    height: auto;
  }

  @media (min-width: 960px) {
    &.drop-shadow::after {
      position: absolute;
      bottom: -20px;
      left: -20px;
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(black, 0.15);
      border: 5px solid white;
    }
  }
  .img-caption {
    @include font-size(28px);

    margin-top: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }
}
